/**
 * Guarantee Grid for editing Guarantee Facts
 *
 * @author Todd Hay
 *
 */

// Libraries
import React, { FC, useCallback, useEffect, useState } from "react";

// Fields to display in the form
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable, { MUIDataTableColumn } from "mui-datatables";
import Alert from "@mui/material/Alert";
import deepEqual from "deep-equal";
import { getAll, insert, update } from "../../utils/dataAccess";
import { MuiForm5 as Form } from "@rjsf/material-ui";
import makeStyles from "@mui/styles/makeStyles";
import { guaranteeColumnsFactory } from "../../model/guarantee/GuaranteeColumnsFactory";
import { GuaranteeObjectFieldTemplate } from "./subComponents/GuaranteeObjectFieldTemplate";
import AddIconButton from "../shared/AddIconButton";
import { useNavigate, useParams } from "react-router-dom";
import { guaranteeUiSchema } from "./subComponents/GuaranteeUiSchema";
import { ISubmitEvent } from "@rjsf/core";
import {contract, guarantee, severityString, snack, UserType} from "../../utils/types";
import dateUtils from "../../utils/dateUtils";

const schema = require("../../model/guarantee/Guarantee.json");

const useStyles = makeStyles((theme) => ({
  rjsf: {
    margin: 8,
  },
  formButtons: {
    textAlign: "center",
    margin: theme.spacing(2),
  },
}));

interface Props {
  user: UserType;
  embed?: boolean;
  selectedGuarantee: string;
  contract: contract;
  showTitle?: boolean;
}

export const GuaranteeGrid: FC<Props> = (props): JSX.Element => {
  const { user, embed, selectedGuarantee, showTitle, contract } = props;
  type IdParams = {
    guaranteeId: string;
  };
  const { guaranteeId } = useParams<IdParams>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [allGuarantees, setAllGuarantees] = useState([]);
  const [guaranteeToEdit, setGuaranteeToEdit] = useState<guarantee | null>(null);
  const [snack, setSnack] = useState<snack>({
    show: false,
    alert: "Nothing to see here...",
    severity: "success",
  });

  const initialColumns = [
    "uid", "pid", "cid", "contentType", "content", "locationType", "location", "amount", "startDate", "endDate", "additionalCids"
  ];

  const classes = useStyles();

  let getGuarantees = useCallback(() => {
    let whereClause: string[] = [`(EXISTS(SELECT * FROM UNNEST(additionalCids) AS x WHERE x = ${selectedGuarantee}) OR cid = ${selectedGuarantee})`, 'active =  TRUE'];
    getAll("guarantees", ["*"], whereClause)
        .then((x) => {
          setAllGuarantees(x);
          setLoading(false);
          if (guaranteeId) {
            onEdit(parseInt(guaranteeId), x);
          }
        })
        .catch((err) => {
          setSnackDetails("Error getting guarantees.", "error");
          console.log(err);
        });
  }, [selectedGuarantee, guaranteeId]);

  useEffect(() => {
    getGuarantees();
  }, [getGuarantees]);

  const setSnackDetails = (alert: any, severity: severityString) => {
    setSnack({
      show: true,
      severity: severity,
      alert,
    });
  };

  const onAddNew = () => {
    setGuaranteeToEdit({ uid: 0, pid: parseInt(selectedGuarantee) });
  };

  const handleFormClose = (formUpdatedOrSubmitted: boolean) => {
    if (formUpdatedOrSubmitted) {
      getGuarantees();
    }
    setGuaranteeToEdit(null);
  };

  let handleSnackClose = () => {
    setSnack({ show: false, severity: "success", alert: null });
  };

  const onEdit = (passedGuaranteeId: number, passedGuarantees = null) => {
    const guarantee = (passedGuarantees ?? allGuarantees).filter(
        (x: guarantee) => x.uid === passedGuaranteeId
    )[0];
    setGuaranteeToEdit(guarantee);
  };

  let handleSubmit = (
      e: ISubmitEvent<guarantee>,
      nativeEvent: React.FormEvent<HTMLFormElement>
  ) => {
    // Check to make sure we have a record to submit
    const formData = e.formData;
    formData.cid = parseInt(selectedGuarantee);
    formData.pid = contract.pid;
    if (formData.uid === 0) {
      formData.new = true;
    }
    setSubmissionLoading(true);

    if (!formData) {
      console.error("No formData");
      setSubmissionLoading(false);
      setEdit(false);
      setSnackDetails("Cancelled", "info");
      return;
    }

    // Check to see if record has changed
    if (!deepEqual(formData, guaranteeToEdit)) {
      if (formData.new) {
        insert("guarantees", formData)
            .then(() => {
              setSnackDetails("Record Saved", "success");
              setSubmissionLoading(false);
            })
            .catch((err) => {
              console.log("recordGrid Catch");
              console.dir(err);
              setSnackDetails("Record not inserted", "error");
              setSubmissionLoading(false);
            })
            .finally(() => handleFormClose(true));
      } else
        update("guarantees", formData, guaranteeToEdit)
            .then(() => {
              setSnackDetails("Record Saved", "success");
              setSubmissionLoading(false);
            })
            .catch((err) => {
              setSubmissionLoading(false);
              // An API error or partial failure occurred.
              console.log(err);
              setSnackDetails("Update Error", "warning");
              console.log("Update Error");
              if (err.name === "PartialFailureError") {
                // Some rows failed to insert, while others may have succeeded.

                // err.errors (object[]):
                // err.errors[].row (original row object passed to `insert`)
                console.dir("errors object", err.errors[0]);
                console.log("reason code", err.errors[0].errors[0].reason);
                console.log("message", err.errors[0].errors[0].message);
                // err.errors[].errors[].message
                setSnackDetails(
                    `Insert Error: ${err.errors[0].errors[0].message}`,
                    "error"
                );
              }
            })
            .finally(() => handleFormClose(true));
    } else {
      // Record hasn't changed - user cancelled.
      setSnackDetails("No changes made. Operation cancelled.", "info");
      setSubmissionLoading(false);
    }
    getGuarantees();
  };

  let localColumns = localStorage.getItem("guaranteeGrid");
  let displayedColumns;
  if (localColumns != null) {
    let localColumnsObject: { columns: any } = JSON.parse(localColumns);
    displayedColumns = localColumnsObject.columns.filter((obj : any) => obj.display == 'true');
  }

  let generatedColumns: MUIDataTableColumn[] = guaranteeColumnsFactory(onEdit, embed).map((x) => ({
    ...x,
    options: {
      ...x?.options,
      display: localColumns && displayedColumns.length > 0
          ? undefined
          : initialColumns.indexOf(x.name) >= 0,
    },
  }));

  if (loading) {
    return (
        <div style={{ textAlign: "center" }}>
          <CircularProgress style={{ marginTop: "4em", marginBottom: "4em" }} />
        </div>
    );
  }

  return (
      <div>
        <Dialog
            open={guaranteeToEdit !== null}
            onClose={() => handleFormClose(false)}
            fullWidth={true}
            maxWidth="xl"
        >
          <DialogTitle id={`guarantee-form-title`}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                {guaranteeToEdit !== null && guaranteeToEdit?.uid === 0
                    ? "Add new guarantee"
                    : "Edit record Details"}
              </Grid>
              <Grid item xs={8} />
              <Grid item xs={1} style={{ textAlign: "right" }}>
                <IconButton
                    aria-label="close"
                    onClick={() => handleFormClose(false)}
                    size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Form
                className={classes.rjsf}
                schema={schema}
                formData={guaranteeToEdit}
                onSubmit={handleSubmit}
                uiSchema={guaranteeUiSchema}
                ObjectFieldTemplate={GuaranteeObjectFieldTemplate}
            >
              <Grid container={true} spacing={2} className={classes.formButtons}>
                <Grid item={true} xs={5} />
                <Grid item={true} xs={1}>
                  {submissionLoading ? (
                      <CircularProgress />
                  ) : (
                      <Button variant="outlined" color="primary" type="submit">
                        Submit
                      </Button>
                  )}
                </Grid>
                <Grid item={true} xs={1}>
                  <Button
                      variant="outlined"
                      color="secondary"
                      type="button"
                      onClick={(formData: any) => {
                        formData.changed = false;
                        handleFormClose(true);
                      }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item={true} xs={5} />
              </Grid>
            </Form>
          </DialogContent>
        </Dialog>
        <MUIDataTable
            title={showTitle ? "Guarantees" : ""}
            columns={generatedColumns}
            data={allGuarantees}
            options={{
              selectableRows: "none",
              storageKey: "guaranteeGrid",
              enableNestedDataAccess: ".",
              downloadOptions: {
                filename: `PartnerFacts-Guarantees-${dateUtils.fileNameDate()}`,
                filterOptions: {
                  useDisplayedColumnsOnly: true
                }
              },
              customToolbar: () => <AddIconButton handleClick={onAddNew} />,
            }}
        />
        <Snackbar
            open={snack.show}
            autoHideDuration={6000}
            onClose={handleSnackClose}
        >
          <Alert severity={snack.severity} onClose={handleSnackClose}>
            {snack.alert}
          </Alert>
        </Snackbar>
      </div>
  );
};
