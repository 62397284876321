import React, { FC, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card, Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { LineItemEntry } from "./lineItemEntry";
import { AddBox, ExpandMoreRounded } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { lineItem } from "../../utils/types";
import { AddLineItemDialog } from "./AddLineItemDialog";

interface Props {
  lineItems: lineItem[];
  cid: number | null | undefined;
  getAll: () => void;
}
export const LineItemEntryList: FC<Props> = ({
  lineItems,
  cid,
  getAll,
}: Props): JSX.Element => {
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);

  const handleModalToggle = () => {
    setAddModalOpen(!addModalOpen);
  };
  return (
    <>
      <Container  maxWidth={"lg"}>
        <Paper>
          <Grid container>
            <Grid xs={12} mt={2} item>
              <Box display="flex" sx={{ justifyContent: "flex-end" }}>
                <IconButton onClick={handleModalToggle}>
                  <AddBox />
                </IconButton>
              </Box>
            </Grid>
            <Grid xs={12} item>
              {lineItems.map((lineItem) => {
                return (
                  <>
                    <Card
                      sx={{ my: 2, mx: 4, px: 3, py: 3 }}
                      variant="outlined"
                    >
                      <LineItemEntry
                        key={lineItem.uid}
                        lineItem={lineItem}
                        getAll={getAll}
                      />
                    </Card>
                  </>
                );
              })}
            </Grid>
          </Grid>
          {addModalOpen && (
            <AddLineItemDialog
              setDialogClosed={handleModalToggle}
              cid={cid}
              getAll={getAll}
            />
          )}
        </Paper>
      </Container>
    </>
  );
};
