import React, { Dispatch, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { CheckCircle, Error } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import { updateApproval } from "../../../utils/dataAccess";
import Link from "@mui/material/Link";

interface Props {
  uid: number;
  userteam: string;
  status: boolean | null;
  comments: string;
  dateAdded: string;
  dateEdited: string;
  email: string;
  cid: number;
  getFnc: () => void;
  setSnackFnc: any;
  name: string | null;
  setLoading: (b: boolean) => void;
}

export const ApprovalItem: React.FC<Props> = ({
  uid,
  cid,
  dateAdded,
  dateEdited,
  userteam,
  status,
  comments,
  email,
  name,
  getFnc,
  setSnackFnc,
  setLoading,
}: Props) => {
  const [officialApproved, setOfficalApproved] = useState<boolean | null>(null);

  const [expand, setExpand] = useState<boolean>(false);

  const [editable, setEditable] = useState<boolean>(false);

  const [comment, setComment] = useState<string>("");
  const [approvalStatus, setApprovalStatus] = useState<string>("");
  const [approvalBool, setApprovalBool] = useState<boolean | null>(status);

  useEffect(() => {
    setOfficalApproved(status);
    findApprovalStatus(status);
    defineEditables();
  }, [status]);

  useEffect(() => {
    findApprovalBool(approvalStatus);
  }, [approvalStatus]);

  const defineEditables = () => {
    setComment(comments);
  };

  const findApprovalStatus = (app: any) => {
    switch (app) {
      case true:
        setApprovalStatus("Approved");
        break;
      case false:
        setApprovalStatus("Rejected");
        break;
      case null:
        setApprovalStatus("Pending");
        break;
      default:
        break;
    }
  };

  const findApprovalBool = (app: string) => {
    switch (app) {
      case "Pending":
        setApprovalBool(null);
        break;
      case "Rejected":
        setApprovalBool(false);
        break;
      case "Approved":
        setApprovalBool(true);
        break;
      default:
        break;
    }
  };

  const changeApprovalStatus = async (
    event: SelectChangeEvent<typeof approvalStatus>
  ) => {
    setApprovalStatus(event.target.value);
  };

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleSaveClick = () => {
    setLoading(true);
    let reqOb = {
      uid: uid,
      cid: cid,
      dateAdded: dateAdded,
      dateEdited: new Date().toISOString().slice(0, 19).replace("T", " "),
      comment: comment,
      isApproved: approvalBool,
      userEmail: email,
      team: userteam,
      active: true,
    };

    updateApproval("approvals", reqOb)
      .then((res: any) => {
        if (res.status === 200) {
          setEditable(false);
          setSnackFnc({
            show: true,
            alert: "Save Successful",
            severity: "success",
          });
          getFnc();
        } else {
          alert(res.response.status);
          setSnackFnc({
            show: true,
            alert: `Save Unsuccessful: Code ${res.response.status}`,
            severity: "error",
          });
        }
      })
      .catch((err: any) => {
        console.error("Update Res Error--->", err);
      });
  };

  const handleCommentChange = (text: any) => {
    setComment(text);
  };

  return (
    <>
      <Card
        sx={{
          borderRadius: "10px",
          margin: "50px",
          minHeight: "10vh",
          maxWidth: "60vw",
          boxShadow: "5px 5px 13px  gray",

          border: officialApproved
            ? "10px solid #e3ffde"
            : officialApproved === null
            ? "10px solid #ffe5b3"
            : "10px solid #ffcfcf",
        }}
      >
        <Grid container>
          <Grid xs={6} item>
            <Typography
              sx={{ marginLeft: "3%", marginTop: "5%" }}
              variant={"h4"}
            >
              {userteam}
            </Typography>
          </Grid>

          <Grid xs={6} item>
            <CardActions>
              {editable ? (
                //if editable is enabled, show save icon instead of edit icon
                <IconButton
                  sx={{ marginLeft: "auto" }}
                  color={"primary"}
                  aria-label={"Edit"}
                  onClick={handleSaveClick}
                >
                  <SaveIcon />
                </IconButton>
              ) : (
                <IconButton
                  sx={{ marginLeft: "auto" }}
                  color={"primary"}
                  aria-label={"Edit"}
                  onClick={handleEditClick}
                >
                  <EditIcon />
                </IconButton>
              )}
            </CardActions>
          </Grid>
        </Grid>
        <CardContent>
          {editable ? (
            <>
              <FormControl sx={{ marginBottom: "20px" }} fullWidth>
                <InputLabel id="select-item">Status</InputLabel>
                <Select
                  labelId="select-item"
                  label={"select"}
                  title={"Status"}
                  value={approvalStatus}
                  defaultValue={"pending"}
                  onChange={changeApprovalStatus}
                >
                  <MenuItem value={"Approved"}>Approved</MenuItem>
                  <MenuItem value={"Rejected"}>Rejected</MenuItem>
                  <MenuItem value={"Pending"}>Pending</MenuItem>
                </Select>
              </FormControl>

              <TextField
                label="Comment"
                multiline
                fullWidth
                defaultValue={comment}
                onChange={(e) => handleCommentChange(e.target.value)}
              />
            </>
          ) : (
            <>
              <Typography variant={"h5"}>
                <strong>Status:</strong> {approvalStatus}
              </Typography>
              <Typography variant={"h5"}>
                <strong>Comments: </strong> {comments}
              </Typography>
            </>
          )}
          <Grid item>
            <Grid container>
              <Grid item xs={6}>
                <Typography>
                  Last Editor:
                  <Link
                    href={`mailto:${email}?subject= Regarding ${name}: ${userteam} Approval `}
                  >
                    {email}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box
                  display={"flex"}
                  justifyContent={"right"}
                  alignItems={"center"}
                >
                  <Typography variant={"h4"} align={"right"}>
                    {officialApproved ? (
                      <>
                        <CheckCircle style={{ fill: "green" }} />
                        Approved
                      </>
                    ) : officialApproved === null ? (
                      <>
                        <PauseCircleFilledIcon
                          style={{ fill: "rgb(234,199,94)" }}
                        />
                        Pending
                      </>
                    ) : (
                      <>
                        <Error style={{ fill: "#dc4444" }} />
                        Not Approved
                      </>
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>

        <Collapse in={expand}>
          <Typography>This is hidden</Typography>
        </Collapse>
      </Card>
    </>
  );
};
