/**
 * Contact Grid for editing Contact Facts
 *
 * @author Todd Hay
 *
 */

// Libraries
import React, { FC, useCallback, useEffect, useState } from "react";

// Fields to display in the form
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable, { MUIDataTableColumn } from "mui-datatables";
import Alert from "@mui/material/Alert";
import deepEqual from "deep-equal";
import { getAll, insert, update } from "../../utils/dataAccess";
import { MuiForm5 as Form } from "@rjsf/material-ui";
import makeStyles from "@mui/styles/makeStyles";
import { contactColumnsFactory } from "../../model/contact/ContactColumnsFactory";
import { ContactObjectFieldTemplate } from "./subComponents/ContactObjectFieldTemplate";
import AddIconButton from "../shared/AddIconButton";
import { useNavigate, useParams } from "react-router-dom";
import { contactUiSchema } from "./subComponents/ContactUiSchema";
import { ISubmitEvent } from "@rjsf/core";
import {contact, severityString, snack, UserType} from "../../utils/types";
import dateUtils from "../../utils/dateUtils";

const schema = require("../../model/contact/Contact.json");

const useStyles = makeStyles((theme) => ({
  rjsf: {
    margin: 8,
  },
  formButtons: {
    textAlign: "center",
    margin: theme.spacing(2),
  },
}));

interface Props {
  user: UserType;
  embed?: boolean;
  selectPartner?: any;
  showTitle?: boolean;
}

export const ContactGrid: FC<Props> = (props): JSX.Element => {
  const { user, embed, selectPartner, showTitle } = props;
  type IdParams = {
    contactId: string;
  };
  const { contactId } = useParams<IdParams>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [allContacts, setAllContacts] = useState([]);
  const [contactToEdit, setContactToEdit] = useState<contact | null>(null);
  const [snack, setSnack] = useState<snack>({
    show: false,
    alert: "Nothing to see here...",
    severity: "success",
  });

  const initialColumns = [
    "name",
    "email",
    "lastEdited",
    "lastEditedBy",
    "actions",
  ];

  const classes = useStyles();

  let getContacts = useCallback(() => {
    let whereClause: [string] = ["active = TRUE"];
    if (selectPartner) {
      whereClause.push(`pid = ${selectPartner}`);
    }
    getAll("contacts", ["*"], whereClause)
      .then((x) => {
        setAllContacts(x);
        setLoading(false);
        if (contactId) {
          onEdit(parseInt(contactId), x);
        }
      })
      .catch((err) => {
        setSnackDetails("Error getting contacts.", "error");
        console.log(err);
      });
  }, [selectPartner, contactId]);

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  const setSnackDetails = (alert: any, severity: severityString) => {
    setSnack({
      show: true,
      severity: severity,
      alert,
    });
  };

  const onAddNew = () => {
    setContactToEdit({ uid: 0 });
  };

  const handleFormClose = (formUpdatedOrSubmitted: boolean) => {
    if (formUpdatedOrSubmitted) {
      getContacts();
    }
    setContactToEdit(null);
    navigate("/contacts");
  };

  let handleSnackClose = () => {
    setSnack({ show: false, severity: "success", alert: null });
  };

  const onEdit = (passedContactId: number, passedContacts = null) => {
    if (!contactId) {
      navigate(`/contacts/${passedContactId}`);
    }
    const contact = (passedContacts ?? allContacts).filter(
      (x: contact) => x.uid === passedContactId
    )[0];
    setContactToEdit(contact);
  };

  let handleSubmit = (
    e: ISubmitEvent<contact>,
    nativeEvent: React.FormEvent<HTMLFormElement>
  ) => {
    // Check to make sure we have a record to submit
    const formData = e.formData;
    if (formData.uid === 0) {
      formData.new = true;
    }
    setSubmissionLoading(true);

    if (!formData) {
      console.error("No formData");
      setSubmissionLoading(false);
      setEdit(false);
      setSnackDetails("Cancelled", "info");
      return;
    }

    // Check to see if record has changed
    if (!deepEqual(formData, contactToEdit)) {
      formData.lastEditedBy = user.profileObj.email;
      if (formData.new) {
        insert("contacts", formData)
          .then(() => {
            setSnackDetails("Record Saved", "success");
            setSubmissionLoading(false);
          })
          .catch((err) => {
            console.log("recordGrid Catch");
            console.dir(err);
            setSnackDetails("Record not inserted", "error");
            setSubmissionLoading(false);
          })
          .finally(() => handleFormClose(true));
      } else
        update("contacts", formData, contactToEdit)
          .then(() => {
            setSnackDetails("Record Saved", "success");
            setSubmissionLoading(false);
          })
          .catch((err) => {
            setSubmissionLoading(false);
            // An API error or partial failure occurred.
            console.log(err);
            setSnackDetails("Update Error", "warning");
            console.log("Update Error");
            if (err.name === "PartialFailureError") {
              // Some rows failed to insert, while others may have succeeded.

              // err.errors (object[]):
              // err.errors[].row (original row object passed to `insert`)
              console.dir("errors object", err.errors[0]);
              console.log("reason code", err.errors[0].errors[0].reason);
              console.log("message", err.errors[0].errors[0].message);
              // err.errors[].errors[].message
              setSnackDetails(
                `Insert Error: ${err.errors[0].errors[0].message}`,
                "error"
              );
            }
          })
          .finally(() => handleFormClose(true));
    } else {
      // Record hasn't changed - user cancelled.
      setSnackDetails("No changes made. Operation cancelled.", "info");
      setSubmissionLoading(false);
    }
    getContacts();
  };

  let localColumns = localStorage.getItem("contactGrid");
  let displayedColumns;
  if (localColumns != null) {
    let localColumnsObject: { columns: any } = JSON.parse(localColumns);
    displayedColumns = localColumnsObject.columns.filter((obj : any) => obj.display == 'true');
  }

  let generatedColumns: MUIDataTableColumn[] = contactColumnsFactory(
    onEdit,
    embed
  ).map((x) => ({
    ...x,
    options: {
      ...x?.options,
      display: localColumns && displayedColumns.length > 0
        ? undefined
        : initialColumns.indexOf(x.name) >= 0,
    },
  }));

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress style={{ marginTop: "4em", marginBottom: "4em" }} />
      </div>
    );
  }

  return (
    <div>
      <Dialog
        open={contactToEdit !== null}
        onClose={() => handleFormClose(false)}
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle id={`contact-form-title`}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              {contactToEdit !== null && contactToEdit?.uid === 0
                ? "Add new contact"
                : "Edit record Details"}
            </Grid>
            <Grid item xs={8} />
            <Grid item xs={1} style={{ textAlign: "right" }}>
              <IconButton
                aria-label="close"
                onClick={() => handleFormClose(false)}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Form
            className={classes.rjsf}
            schema={schema}
            formData={contactToEdit}
            onSubmit={handleSubmit}
            uiSchema={contactUiSchema}
            ObjectFieldTemplate={ContactObjectFieldTemplate}
          >
            <Grid container={true} spacing={2} className={classes.formButtons}>
              <Grid item={true} xs={5} />
              <Grid item={true} xs={1}>
                {submissionLoading ? (
                  <CircularProgress />
                ) : (
                  <Button variant="outlined" color="primary" type="submit">
                    Submit
                  </Button>
                )}
              </Grid>
              <Grid item={true} xs={1}>
                <Button
                  variant="outlined"
                  color="secondary"
                  type="button"
                  onClick={(formData: any) => {
                    formData.changed = false;
                    handleFormClose(true);
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item={true} xs={5} />
            </Grid>
          </Form>
        </DialogContent>
      </Dialog>
      <MUIDataTable
        title={showTitle ? "Contacts" : ""}
        columns={generatedColumns}
        data={allContacts}
        options={{
          selectableRows: "none",
          storageKey: "contactGrid",
          enableNestedDataAccess: ".",
          downloadOptions: {
            filename: `PartnerFacts-Contacts-${dateUtils.fileNameDate()}`,
            filterOptions: {
              useDisplayedColumnsOnly: true
            }
          },
          customToolbar: () => <AddIconButton handleClick={onAddNew} />,
        }}
      />
      <Snackbar
        open={snack.show}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert severity={snack.severity} onClose={handleSnackClose}>
          {snack.alert}
        </Alert>
      </Snackbar>
    </div>
  );
};
