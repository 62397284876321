/**
 * Utility function to create the contractColumns to be used in the table
 *
 *
 */

import React from "react";

import dateUtils from "../../utils/dateUtils";
import LinkRouter from "../../components/shared/LinkRouter";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { HourglassEmpty } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { LibraryAdd } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import makeStyles from "@mui/styles/makeStyles";
import { MUIDataTableColumn } from "mui-datatables";
import { CheckCircle } from "@mui/icons-material";
import { getAll } from "../../utils/dataAccess";

const useStyles = makeStyles((theme) => ({
  link: {
    backgroundColor: theme.palette.primary.main,
    borderTop: "2px solid #EAAF00",
  },
}));

export const contractColumnsFactory = (
  onEdit: Function,
  onAmend: Function,
  onCopy: Function,
  embed: any,
  allowDelete: boolean,
  setContractToDelete: Function
): MUIDataTableColumn[] =>
  [
    {
      name: "uid",
      label: "uid",
    },
    {
      name: "pid",
      label: "pid",
    },
    {
      name: "sid",
      label: "Related Partner Name",
      options: {
        customBodyRender:
          (
            value: any,
            tableMeta: { tableData: any[]; rowData: any[] },
            updateValue: any
          ) =>
          () => {
            if (value) {
              let relatedPartner = tableMeta.tableData.find(
                (x) => x[0] === value
              );
              if (relatedPartner) {
                return (
                  <a href={`/contracts/${tableMeta.rowData[2]}`}>
                    {relatedPartner[3]}
                  </a>
                );
              }
            }
          },
      },
    },
    {
      name: "partner_name",
      label: "Partner",
      options: {
        customBodyRender:
          (value: any, tableMeta: { rowData: any[] }, updateValue: any) =>
          () => {
            return (
              <LinkRouter to={`/partners/${tableMeta.rowData[1]}`}>
                {value}
              </LinkRouter>
            );
          },
        display: !embed,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: embed
          ? (value: any, tableMeta: { rowData: any[] }, updateValue: any) =>
              () => {
                return (
                  <LinkRouter to={`/contracts/${tableMeta.rowData[0]}`}>
                    {value}
                  </LinkRouter>
                );
              }
          : null,
      },
    },
    {
      name: "partner_id",
      label: "Contract Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any) => {
          return <p>{value === "epg" ? "fast" : value}</p>;
        },
      },
    },
    {
      name: "effectiveDate",
      label: "Effective Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "isPending",
      label: "Pending",
      options: {
        filter: true,
        display: true,
        customBodyRender: (value: any) => {
          return <div>{value ? <HourglassEmpty /> : <CheckCircle />}</div>;
        },
      },
    },

    {
      name: "contractSource",
      label: "Source",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "lastEdited",
      label: "Last Edit",
      options: {
        filter: true,
        sort: true,
        customBodyRender: dateUtils.formatDate,
      },
    },
    {
      name: "lastEditedBy",
      label: "Last Editor",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "dateAdded",
      label: "Date Added",
      options: {
        filter: true,
        sort: true,
        customBodyRender: dateUtils.formatDate,
      },
    },
    {
      name: "contractUrl",
      label: "Url",
      options: {
        customBodyRender: (value: any) => {
          return (
            <a target="_blank" href={value} rel="noreferrer">
              {" "}
              {value}
            </a>
          );
        },
      },
    },
    {
      name: "terms.shareType",
      label: "Share Type",
    },
    {
      name: "terms.revenueShare",
      label: "Rev Share",
    },
    {
      name: "terms.inventoryShare",
      label: "Inv Share",
    },
    embed
      ? {
          name: "actions",
          label: "Actions",
          options: {
            empty: true,
            customBodyRender: (
              value: any,
              tableMeta: { rowData: any[] },
              updateValue: any
            ) => (
              <>
                <Tooltip title={"Add Amendment"}>
                  <IconButton
                    onClick={() => onAmend(tableMeta.rowData[0])}
                    size="large"
                  >
                    <LibraryAdd />
                  </IconButton>
                </Tooltip>
              </>
            ),
          },
        }
      : {
          name: "actions",
          label: "Actions",
          options: {
            empty: true,
            customBodyRender: (
              value: any,
              tableMeta: { rowData: any[] },
              updateValue: any
            ) => (
              <>
                <Tooltip title={"Edit Contract"}>
                  <IconButton
                    onClick={() => onEdit(tableMeta.rowData[0])}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Add Amendment"}>
                  <IconButton
                    onClick={() => onAmend(tableMeta.rowData[0])}
                    size="large"
                  >
                    <LibraryAdd />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Duplicate Contract"}>
                  <IconButton
                    onClick={() => onCopy(tableMeta.rowData[0])}
                    size="large"
                  >
                    <CopyAllIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Minimum Guarantees"}>
                  <LinkRouter to={`/guarantees/${tableMeta.rowData[0]}`}>
                    <IconButton size="large">
                      <RequestQuoteIcon />
                    </IconButton>
                  </LinkRouter>
                </Tooltip>
                <Tooltip title={"Approvals"}>
                  <LinkRouter to={`/approvals/${tableMeta.rowData[0]}`}>
                    <IconButton size="large">
                      <CheckCircle />
                    </IconButton>
                  </LinkRouter>
                </Tooltip>
                {allowDelete ? (
                  <Tooltip title={"Delete Record"}>
                    <IconButton
                      onClick={() => setContractToDelete(tableMeta.rowData[0])}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </>
            ),
          },
        },
  ].filter((x) => x) as MUIDataTableColumn[];
