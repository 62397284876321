/**
 * Contract for for editing the full record.
 *
 * @todo    Investigate if Dialog would be better to contain the form - remove buttons from form
 * @todo    Figure out if we want links to contracts, contacts and reports here - at least an Add Contact, Add Contract button
 * @todo    Add Drawers?  Contracts, Contacts, Reports - using grid components?
 * @todo    can you do a dialog in a dialog? if Not - perhaps add routing, and create a partner page with drawers.
 */

import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Container,
  Snackbar,
} from "@mui/material";

import { useParams } from "react-router-dom";
import { getAll } from "../../utils/dataAccess";
import dateUtils from "../../utils/dateUtils";
import PartnerFormDialog from "./PartnerFormDialog";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import makeStyles from "@mui/styles/makeStyles";
import NotesListing from "../shared/NotesListing";

const useStyles = makeStyles((theme) => ({
  dataPointContainer: {
    minWidth: "230px",
  },
  pageContainer: {
    marginTop: "30px",
    marginBottom: "30px",
  },
}));
/**
 *
 * @param {formData, callback} props    Expects populated object following "schema"
 */
export default function PartnerDetails({ user, onPartnerLoaded }: any) {
  type PartnerParams = {
    partnerId: string;
  };
  const { partnerId } = useParams<PartnerParams>();
  const classes = useStyles();
  const [partner, setPartner] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [allPartners, setAllPartners] = useState([]);
  const [snack, setSnack] = useState<{
    show: boolean;
    alert: any;
    severity: severityString;
  }>({
    show: false,
    alert: "Nothing to see here...",
    severity: "success",
  });

  type severityString = "success" | "error" | "warning" | "info";

  let getPartners = useCallback(() => {
    if(!partnerId) {
      return;
    }
    setLoading(true);
    getAll("partners", ["*"], ["active = TRUE"])
      .then((x) => {
        setAllPartners(x);
        const p = x.filter(
          (y: { uid: number }) => y.uid === parseInt(partnerId)
        );
        setPartner(p[0]);
        if (onPartnerLoaded) {
          onPartnerLoaded(p[0]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setSnack({
          show: true,
          alert: "Error getting partners.",
          severity: "error",
        });
        console.log(err);
      });
  }, [partnerId]);

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  let handleSnackClose = () => {
    setSnack({ ...snack, show: false });
  };

  const handleEditDialogOpen = () => {
    setShowEditDialog(true);
  };

  const handleFormClose = (formUpdatedOrSubmitted: any) => {
    if (formUpdatedOrSubmitted) {
      getPartners();
    }
    setShowEditDialog(false);
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  const DataPoint = ({ name, value }: any) => (
    <div style={{ padding: "4px" }}>
      <Typography variant="subtitle1">{name}</Typography>
      <Typography variant="body2">{value}</Typography>
    </div>
  );
  return (
    <div className={classes.pageContainer}>
      <Container maxWidth={"lg"}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h4">{partner.partner}</Typography>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={10}>
                <Grid container>
                  <Grid item xs={6} className={classes.dataPointContainer}>
                    <DataPoint name="Type" value={partner.type} />
                  </Grid>
                  <Grid item xs={6} className={classes.dataPointContainer}>
                    <DataPoint name="Partner Manager" value={partner.owner} />
                  </Grid>
                  <Grid item xs={6} className={classes.dataPointContainer}>
                    <DataPoint
                      name="Last Edited"
                      value={dateUtils.formatDate(partner.lastEdited)}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.dataPointContainer}>
                    <DataPoint
                      name="Last Edited By"
                      value={partner.lastEditedBy}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.dataPointContainer}>
                    <DataPoint
                      name="Date Added"
                      value={dateUtils.formatDate(partner.dateAdded)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "right" }}>
                <IconButton onClick={handleEditDialogOpen} size="large">
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h5">Notes</Typography>
            <NotesListing notes={partner.notes} />
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={snack.show}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert severity={snack.severity} onClose={handleSnackClose}>
          {snack.alert}
        </Alert>
      </Snackbar>
      <PartnerFormDialog
        user={user}
        allPartners={allPartners}
        partnerId={parseInt(partnerId!)}
        show={showEditDialog}
        setSnack={setSnack}
        onClose={handleFormClose}
      />
    </div>
  );
}
