import React, {Context, useCallback, useContext, useEffect, useState} from "react";
import {
  Container,
  createTheme,
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";

import Header from "./components/Header";

import Footer from "./components/footer";
import Login from "./components/Login";
import { isLoggedInWithValidToken } from "./utils/authorization";
import MainRouter from "./MainRouter";

// it could be your App.tsx file or theme file that is included in your tsconfig.json
import { Theme } from "@mui/material/styles";
import { UserType } from "./utils/types";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {settings} from "./settings";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

/**
 * Main App container
 *
 * Controls Login State - through owning user object to pass into children.
 *
 * @todo Replace div with MUI container - BaseCSS? Paper?
 *
 */

const theme = createTheme({
  palette: {
    primary: {
      main: "#264183",
    },
    secondary: {
      main: "#3C434B",
      light: "#B2BAC2",
    },
  },
});
console.log("Environment Variables-- ");

console.log("REACT_APP_PRIVATE_KEY_ID", process.env.REACT_APP_PRIVATE_KEY_ID);
console.log("REACT_APP_PRIVATE_KEY", process.env.REACT_APP_PRIVATE_KEY);
console.log("REACT_APP_CLIENT_ID", process.env.REACT_APP_CLIENT_ID);
console.log("REACT_APP_PROJECT_ID", process.env.REACT_APP_PROJECT_ID);
console.log("REACT_APP_EMAIL", process.env.REACT_APP_EMAIL);
console.log("REACT_APP_LOCATION", process.env.REACT_APP_LOCATION);
console.log("REACT_APP_HOSTED_DOMAIN", process.env.REACT_APP_HOSTED_DOMAIN);
console.log("SKIP_PREFLIGHT_CHECK", process.env.SKIP_PREFLIGHT_CHECK);

function App() {
  const [user, setUserDetails] = useState<UserType>({
    profileObj: { name: "", imageUrl: "", email: "" },
  });
  //
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    processUserLogin();
  }, []);


  const processUserLogin = async () => {
    const loginStatus = await isLoggedInWithValidToken();
    setIsLoggedIn(loginStatus);
    loadUserInfo();
    setLoading(false);
  };

  const loadUserInfo = () =>{
    const user = localStorage.getItem('user-info')
    if(!user) return;
    const userObject = JSON.parse(user) as UserType;
    setUserDetails(userObject)
  }

  const copyrightString: string = `Plex Partner Facts ${new Date().getFullYear()}`;
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GoogleOAuthProvider clientId={settings.REACT_APP_CLIENT_ID}>
          <Header>
            <Login
              user={user}
              onLogin={(x: any) => setUserDetails(x)}
              loadingStatus={loading}
              loginStatus={isLoggedIn}
              processUserLogin={processUserLogin}
            />
          </Header>
          {isLoggedIn ? (
            <MainRouter user={user} />
          ) : (
            !loading && (
              <Container>
                <h1 style={{ textAlign: "center" }}>
                  You need to log in to access this application.
                </h1>
              </Container>
            )
          )}
          <Footer copyright={copyrightString} />
        </GoogleOAuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
