import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { updateApproval } from "../../../utils/dataAccess";
import { ApprovalListing } from "../../../model/approval/approval";
import { UserType } from "../../../utils/types";
import { FinanceStats } from "../subComponents/DivStats/FinanceStats";

interface Contract {
  name: string;
  cid: number;
  uid: number;
  userEmail: string;
  isApproved: boolean;
  team: string;
  effectiveDate: string;
  terms: any;
  dateAdded: string;
  endDate: string;
  comment: string;
}

interface Terms {
  division: string;
  searchTerm: string;
}

interface Props {
  approvalListing: ApprovalListing;
  searchFnc: (division: string, searchTerm: string) => Promise<void>;
  searchObj: Terms;
  setIsLoading: (b: boolean) => void;
  user: UserType;
}

export const ApprovalsItem: React.FC<Props> = ({
  approvalListing,
  searchFnc,
  searchObj,
  setIsLoading,
  user,
}: Props) => {
  const [approvalStatus, setApprovalStatus] = useState<
    boolean | null | undefined
  >(undefined);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");

  useEffect(() => {
    setComment(approvalListing.comment || "");
    setApprovalStatus(approvalListing.isApproved);
  }, []);
  const stateSwitch = (bool: boolean | null) => {
    switch (bool) {
      case true:
        return "Approved";
      case false:
        return "Rejected";
      case null:
        return "Pending";
      default:
        break;
    }
  };
  //@ToDo: Insert Custom Views For Each Department
  const divSwitch = (department: string) => {
    switch (department) {
      case "Finance":
        return <FinanceStats approvalListing={approvalListing} />;
      case "Business Development":
        return <FinanceStats approvalListing={approvalListing} />;
      case "Ad Operations":
        return <FinanceStats approvalListing={approvalListing} />;
      case "Content Operations":
        return <FinanceStats approvalListing={approvalListing} />;
      default:
        break;
    }
  };

  const handleSelectionChange = (event: SelectChangeEvent) => {
    setApprovalStatus(JSON.parse(`${event.target.value}`.toLowerCase()));
  };

  return (
    <>
      <Card
        sx={{
          minHeight: "10vh",
          margin: "20px",
          backgroundColor: "#f3f3f3",
          borderRadius: "1vw",
          fontSize: "1rem",
        }}
      >
        <Box sx={{ padding: "1vw" }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={8}>
                  <Link to={`/contracts/${approvalListing.cid}`}>
                    {approvalListing.contract.name}
                  </Link>
                </Grid>

                <Grid item xs={4}>
                  <Box sx={{ textAlign: "right" }}>
                    {isEditable ? (
                      <IconButton
                        color={"primary"}
                        aria-label={"Save"}
                        onClick={() => {
                          setIsLoading(true);
                          let reqOb = {
                            uid: approvalListing.uid,
                            cid: approvalListing.cid,
                            dateAdded: approvalListing.dateAdded,
                            dateEdited: new Date()
                              .toISOString()
                              .slice(0, 19)
                              .replace("T", " "),
                            comment: comment,
                            isApproved: approvalStatus,
                            userEmail: user.profileObj.email,
                            team: approvalListing.team,
                            active: true,
                          };
                          //@ToDo: Set snacks
                          updateApproval("approvals", reqOb)
                            .then((res) => {
                              if (res.status === 200) {
                                setIsEditable(false);
                                searchFnc(
                                  searchObj.division,
                                  searchObj.searchTerm
                                );
                                // Set a snack here
                              } else {
                                //Set a snack here
                              }
                            })
                            .catch((err: any) => {
                              console.error(err);
                            });
                        }}
                      >
                        <SaveIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        sx={{ marginLeft: "auto" }}
                        color={"primary"}
                        aria-label={"Edit"}
                        onClick={() => {
                          setIsEditable(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant={"h5"}>
                    {stateSwitch(approvalListing.isApproved)}
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "end",
                      gap: "10px",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="comment-input">
                        Select Approval Status
                      </InputLabel>
                      <Select
                        disabled={!isEditable}
                        label="edit approval status"
                        labelId={"comment-input"}
                        onChange={handleSelectionChange}
                      >
                        <MenuItem value={"true"}>Approved</MenuItem>
                        <MenuItem value={"false"}>Rejected</MenuItem>
                        <MenuItem value={"null"}>Pending</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <TextField
                        disabled={!isEditable}
                        label="Comment"
                        value={comment}
                        onChange={(
                          event: ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >
                        ) => {
                          setComment(event.target.value);
                        }}
                      />
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Info />
                  <Typography variant={"h5"}>
                    {approvalListing.team} Stats
                  </Typography>
                </Box>

                {divSwitch(approvalListing.team)}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};
