/**
 * Utility function to create the contractColumns to be used in the table
 *
 *
 */


import React from 'react';

import dateUtils from "../../utils/dateUtils";
import LinkRouter from "../../components/shared/LinkRouter";

import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import {MUIDataTableColumn} from "mui-datatables";

/**
 * Defintion of the table headers
 *
 * @todo #6 Will want to dynamically create this based on schema or selected contractColumns
 *
 */
export const partnerColumnsFactory = (onEdit: Function): MUIDataTableColumn[] => [
    {
        name: 'uid',
        label: 'uid',
    },
    {
        name: 'partner',
        label: 'Name',
        options: {
            filter: true,
            sort: true,
            searchable: true,
            customBodyRender: (value: any, tableMeta: { rowData: any[]; }, updateValue: any) => () => {
                return <LinkRouter to={`/partners/${tableMeta.rowData[0]}`}>{value}</LinkRouter>
            }
        }
    },
    {
        name: 'type',
        label: 'Type',
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: 'owner',
        label: 'Owner',
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: 'lastEdited',
        label: 'Last Edit',
        options: {
            filter: true,
            sort: true,
            customBodyRender: dateUtils.formatDate
        }
    },
    {
        name: 'lastEditedBy',
        label: 'Last Editor',
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: 'dateAdded',
        label: 'Date Added',
        options: {
            filter: true,
            sort: true,
            customBodyRender: dateUtils.formatDate
        }
    },
    {
        name: 'actions',
        label: 'Actions',
        options: {
            empty: true,
            customBodyRender: (value: any, tableMeta: { rowData: any[]; }, updateValue: any) => <IconButton size="large">
                <EditIcon onClick={() => onEdit(tableMeta.rowData[0])}/>
            </IconButton>
        }
    }
].filter(x => x) as MUIDataTableColumn[];
