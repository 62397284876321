import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Container,
  Grid,
  Link,
  Snackbar,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { GuaranteeGrid } from "./GuaranteeGrid";
import Alert from "@mui/material/Alert";
import { contract, partner, severityString, snack } from "../../utils/types";
import { getAll } from "../../utils/dataAccess";
import dateUtils from "../../utils/dateUtils";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import LinkRouter from "../shared/LinkRouter";

const useStyles = makeStyles((theme) => ({
  dataPointContainer: {
    minWidth: "230px",
  },
  pageContainer: {
    marginTop: "30px",
    marginBottom: "30px",
  },
}));

function GuaranteePage({ user }: any) {
  const classes = useStyles();
  const [contract, setContract] = useState<contract | null>(null);
  const [partner, setPartner] = useState<partner | null>(null);
  const [loading, setLoading] = useState(true);
  const [snack, setSnack] = useState<snack>({
    show: false,
    alert: "Nothing to see here...",
    severity: "success",
  });
  type IdParams = {
    contractId: string;
  };
  const { contractId } = useParams<IdParams>();

  let getContract = useCallback(() => {
    let whereClause: string[] = ["active = TRUE", `uid = ${contractId}`];
    getAll("contracts", ["*"], whereClause)
      .then((x) => {
        setContract(x[0]);
        getAll("partners", ["*"], ["active = TRUE", `uid = ${x[0].pid}`])
          .then((x) => {
            setPartner(x[0]);
          })
          .catch((err) => {
            setSnackDetails("Error getting partner.", "error");
            console.log(err);
          });
        setLoading(false);
      })
      .catch((err) => {
        setSnackDetails("Error getting contract.", "error");
        console.log(err);
      });
  }, [contractId]);

  useEffect(() => {
    getContract();
  }, [getContract]);

  const setSnackDetails = (alert: any, severity: severityString) => {
    setSnack({
      show: true,
      severity: severity,
      alert,
    });
  };

  let handleSnackClose = () => {
    setSnack({ show: false, severity: "success", alert: null });
  };

  const DataPoint = ({ name, value }: any) => (
    <div style={{ padding: "4px" }}>
      <Typography variant="subtitle1">{name}</Typography>
      <Typography variant="body2">{value}</Typography>
    </div>
  );

  if (loading || contract === null || partner === null) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress style={{ marginTop: "4em", marginBottom: "4em" }} />
      </div>
    );
  }

  return (
    <>
      <div className={classes.pageContainer}>
        <Container maxWidth={"lg"}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h4">{contract.name} Guarantees</Typography>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item xs={10}>
                  <Grid container>
                    <Grid item xs={6} className={classes.dataPointContainer}>
                      <div style={{ padding: "4px" }}>
                        <Typography variant="subtitle1">Partner ID</Typography>
                        <Link
                          href={`/partners/${contract.pid}`}
                          variant={"body2"}
                        >
                          {contract.pid}
                        </Link>
                      </div>
                    </Grid>
                    <Grid item xs={6} className={classes.dataPointContainer}>
                      <DataPoint name="Partner Name" value={partner.partner} />
                    </Grid>
                    <Grid item xs={6} className={classes.dataPointContainer}>
                      <DataPoint name="Contract Name" value={contract.name} />
                    </Grid>
                    <Grid item xs={6} className={classes.dataPointContainer}>
                      <DataPoint name="Type" value={contract.type} />
                    </Grid>
                    {contract.sid && (
                      <Grid item xs={6} className={classes.dataPointContainer}>
                        <div style={{ padding: "4px" }}>
                          <Typography variant="subtitle1">
                            Related Contract
                          </Typography>
                          <Link
                            href={`/contracts/${contract.sid}`}
                            variant={"body2"}
                          >
                            {contract.sid}
                          </Link>
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={6} className={classes.dataPointContainer}>
                      <DataPoint
                        name="Last Edited"
                        value={dateUtils.formatDate(contract.lastEdited ?? "")}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.dataPointContainer}>
                      <DataPoint
                        name="Last Edited By"
                        value={contract.lastEditedBy}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "right" }}>
                  <LinkRouter to={`/contracts/${contractId}`}>
                    <IconButton size="large">
                      <EditIcon />
                    </IconButton>
                  </LinkRouter>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <GuaranteeGrid
                selectedGuarantee={contractId!}
                contract={contract}
                user={user}
                embed={true}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Snackbar
        open={snack.show}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert severity={snack.severity} onClose={handleSnackClose}>
          {snack.alert}
        </Alert>
      </Snackbar>
    </>
  );
}

GuaranteePage.propTypes = {
  user: PropTypes.object.isRequired,
};

export default GuaranteePage;
