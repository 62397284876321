import React, { FC, useState } from "react";
import { lineItem } from "../../utils/types";
import { MuiForm5 as Form } from "@rjsf/material-ui";

import {
  Accordion,
  Typography,
  Box,
  Grid,
  Paper,
  Stack,
  Divider,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
  OpenInFull,
  RoundaboutLeft,
  Edit,
  LocationOff,
  LocationOn,
  Cancel,
  Check,
  ChevronRight,
   ExpandMoreSharp
} from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import { EditLineItemDialog } from "./EditLineItemDialog";
import { termsUISchema } from "./termsUISchemaDisabled";
import { ContractObjectFieldTemplate } from "../contract/subComponents/ContractObjectFieldTemplate";

const termsSchema = require("../../model/contract/terms.json");

interface Props {
  lineItem: lineItem;
  getAll: () => void;
}

export const LineItemEntry: FC<Props> = (props): JSX.Element => {
  const { lineItem, getAll } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const handleOpenInFullClick = () => {
    setModalOpen(!modalOpen);
  };

  const parseExclusivity = (exclusivity: boolean, hasItems: boolean) => {
    switch (exclusivity) {
      case true:
        if (hasItems)
          return (
            <>
              <Stack alignItems="flex-end" direction="row">
                <Typography>Is not one of: </Typography>
              </Stack>
            </>
          );
        return "";

      case false:
        if (hasItems)
          return (
            <>
              <Stack alignItems="flex-end" direction="row">
                <Typography>Is one of:</Typography>
              </Stack>
            </>
          );
        return "";

      default:
        return "";
    }
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        sx={{ position: "relative" }}
      >
        <Box position="absolute" top={0} right={0}>
          <IconButton>
            <Edit onClick={handleOpenInFullClick} />
          </IconButton>
        </Box>
        <Grid xs={12} sx={{ py: 1 }} item></Grid>
        <Grid xs={12} item container>
          <Grid item container alignItems={"baseline"} xs={12}>
            <Grid xs={4} item>
              <Typography variant="h4">Content</Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography variant="h4">Locations</Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography variant="h4">Devices</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid xs={4} item>
              <Divider sx={{ mr: 4 }} light />
              <Typography>
                {" "}
                {parseExclusivity(
                  lineItem.contentIsExclusive,
                  !!lineItem.content.length
                )}
              </Typography>

              {lineItem.content?.map((item) => {
                return (
                  <Chip sx={{ my: 2, mx: 0.5 }} key={item} label={item}></Chip>
                );
              })}
            </Grid>
            <Grid xs={4} item>
              <Divider sx={{ mr: 4 }} light />
              <Typography>
                {" "}
                {parseExclusivity(
                  lineItem.locationsIsExclusive,
                  !!lineItem.locations.length
                )}
              </Typography>
              {lineItem.locations?.map((item) => {
                return (
                  <Chip sx={{ my: 2, mx: 0.5 }} key={item} label={item}></Chip>
                );
              })}
            </Grid>
            <Grid xs={4} item>
              <Divider sx={{ mr: 4 }} light />
              <Typography>
                {" "}
                {parseExclusivity(
                  lineItem.devicesIsExclusive,
                  !!lineItem.devices.length
                )}
              </Typography>
              {lineItem.devices?.map((item) => {
                return (
                  <Chip sx={{ my: 2, mx: 0.5 }} key={item} label={item}></Chip>
                );
              })}
            </Grid>
          </Grid>
          <Grid xs={12} item container>
            <Accordion sx={{ width: "100%" }}>
              <AccordionSummary expandIcon={<ExpandMoreSharp />}>
                <Stack
                  width="100%"
                  alignItems="center"
                  sx={{ mr: 2 }}
                  justifyContent="space-between"
                  direction="row"
                >
                  <Typography variant="h6">Term Changes</Typography>
                  {lineItem.terms?.shareType === "fixed" ? (
                    <>
                      <div>
                        <Typography component="span" fontWeight={400}>
                          Share Type:{" "}
                        </Typography>
                        <Typography component="span">
                          {lineItem.terms?.shareType}
                        </Typography>
                      </div>
                      <div>
                        <Typography component="span" fontWeight={400}>
                          {" "}
                          License Fee:
                        </Typography>
                        <Typography component="span">
                          {lineItem.terms?.licenseFee}
                        </Typography>
                      </div>{" "}
                    </>
                  ) : (
                    <div>
                      <Typography component="span" fontWeight={400}>
                        Share Type:{" "}
                      </Typography>
                      <Typography component="span" fontWeight={400}>
                        {lineItem.terms?.shareType}
                      </Typography>
                    </div>
                  )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Form
                  formData={lineItem.terms}
                  schema={termsSchema}
                  uiSchema={termsUISchema}
                  ObjectFieldTemplate={ContractObjectFieldTemplate}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
      {modalOpen && (
        <>
          <EditLineItemDialog
            lineItem={lineItem}
            setModalClose={handleOpenInFullClick}
            getAll={getAll}
          />
        </>
      )}
    </>
  );
};
