import React, { useEffect, useState } from "react";
import {Box, Grid, Tab, Tabs, Typography} from "@mui/material";
import { EditContract } from "./EditContract";
import { contract, lineItem, UserType } from "../../../utils/types";
import { getContractAndLineItems } from "../../../utils/dataAccess";
import { useParams } from "react-router-dom";
import { LineItemEntryList } from "../../lineItems/lineItemEntryList";

interface AdminProps {
  user: UserType;
  allowDelete: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const EditContractContainer = ({ user, allowDelete }: AdminProps) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [contract, setContract] = useState<contract>();
  const [lineItems, setLineItems] = useState<lineItem[] | null>(null);
  type IdParams = {
    contractId: string;
  };
  const { contractId } = useParams<IdParams>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const refreshData = () => {
    getContractAndLineItems(Number(contractId))
      .then((res) => {
        setContract(res.data[0]);
        setLineItems(res.data[0].lineItems);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <>
      <Grid container xs={12} sx={{mx: 5, my: 5}}>
        <Typography variant="h3">{contract?.name}</Typography>
      </Grid>

      <Tabs value={selectedTab} onChange={handleChange} centered>
        <Tab label="Contract" {...a11yProps(0)} />
        <Tab label="Line Items" {...a11yProps(1)} />
      </Tabs>
      <TabPanel index={0} value={selectedTab}>
        {contract && (
          <EditContract
            user={user}
            allowDelete={allowDelete}
            contract={contract}
          />
        )}
      </TabPanel>
      <TabPanel index={1} value={selectedTab}>
        {lineItems && (
          <LineItemEntryList
            lineItems={lineItems}
            cid={Number(contractId)}
            getAll={refreshData}
          />
        )}
      </TabPanel>
    </>
  );
};
