import React, {FC} from "react";

import {Grid} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {formFieldSizes} from "../../../model/shared/formFieldSizes";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10
    }
}));
/**
 * Called by rjsf to allow me to customize the layout
 * @param {*} param0
 */

interface Props {
    DescriptionField: any,
    TitleField: any,
    description: any,
    title: any,
    properties: any,
    required:boolean,
    uiSchema: any,
    idSchema: any,
}

export const PartnerFieldTemplate: FC<Props> = (props) => {
    const classes = useStyles();
    const { DescriptionField, description, TitleField, title, properties, required, uiSchema, idSchema } = props;
    return (
        <>
            {(uiSchema['ui:title'] || title) && (
                <TitleField
                    id={`${idSchema.$id}-title`}
                    title={title}
                    required={required}
                />
            )}
            {description && (
                <DescriptionField
                    id={`${idSchema.$id}-description`}
                    description={description}
                />
            )}
            <Grid container={true} spacing={2} className={classes.root}>
                {properties.map((element: any, index: string | number | null | undefined) => {
                    var xs = element.content.props.schema.type;
                    if (element.content.props.uiSchema["ui:widget"] !== "hidden")
                        return (
                            <Grid
                                item={true}
                                xs={(formFieldSizes[xs]) ? formFieldSizes[xs] : formFieldSizes['default']}
                                key={index}
                                style={{marginBottom: '10px'}}
                            >
                                {element.content}
                            </Grid>
                        );
                    return false;
                })}
            </Grid>
        </>
    );
};
