import React, {FC} from "react";

import { Grid} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const sizes: any = {
    object: 12,
    array: 12,
    boolean: 2,
    integer: 2,
    string: 4,
    default: 4
};

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10
    },
    rjsf: {
        margin: 8
    },
    formButtons: {
        textAlign: "center",
        margin: theme.spacing(2)

    }
}));

interface Props {
    DescriptionField: any,
    description: string,
    TitleField: any,
    title: string,
    properties: any,
    required: boolean,
    uiSchema: any,
    idSchema: any,
}

export const GuaranteeObjectFieldTemplate: FC<Props> = (props) => {
    const {
        DescriptionField,
            description,
            TitleField,
            title,
            properties,
            required,
            uiSchema,
            idSchema,
    } = props;
    const classes = useStyles();
    return (
        <>
            {(uiSchema['ui:title'] || title) && (
                <TitleField
                    id={`${idSchema.$id}-title`}
                    title={title}
                    required={required}
                />
            )}
            {description && (
                <DescriptionField
                    id={`${idSchema.$id}-description`}
                    description={description}
                />
            )}
            <Grid container={true} spacing={2} className={classes.root}>
                {properties.map((element: any, index: string | number | null | undefined) => {
                    var xs = element.content.props.schema.type;
                    if (element.content.props.uiSchema["ui:widget"] !== "hidden")
                        return (
                            <Grid
                                item={true}
                                xs={sizes[xs]}
                                key={index}
                                style={{marginBottom: '10px'}}
                            >
                                {element.content}
                            </Grid>
                        );
                    return false;
                })}
            </Grid>
        </>
    );
};