/**
 * Contract for for editing the full record.
 *
 * @todo    Investigate if Dialog would be better to contain the form - remove buttons from form
 * @todo    Figure out if we want links to contracts, contacts and reports here - at least an Add Contact, Add Contract button
 * @todo    Add Drawers?  Contracts, Contacts, Reports - using grid components?
 * @todo    can you do a dialog in a dialog? if Not - perhaps add routing, and create a partner page with drawers.
 */

import React, { FC, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Button,
  Grid,
  CircularProgress,
  DialogTitle,
  IconButton,
  Dialog,
  DialogContent,
} from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

// https://react-jsonschema-form.readthedocs.io/en/latest/api-reference/uiSchema/
import { MuiForm5 as Form } from "@rjsf/material-ui";
import { insert, update } from "../../utils/dataAccess";
import deepEqual from "deep-equal";
import CloseIcon from "@mui/icons-material/Close";
import { PartnerFieldTemplate } from "./subComponents/PartnerFieldTemplate";
import { partnerUiSchema } from "./subComponents/PartnerUiSchema";
import {partner, UserType} from "../../utils/types";

// Fields to display in the form
const schema = require("../../model/partner/Partner.json");

/**
 *
 * @param {formData, callback} props    Expects populated object following "schema"
 */

interface Props {
  user: UserType;
  partnerId: number | null | undefined;
  allPartners: any;
  show: boolean;
  onClose: any;
  setSnack: any;
}
export const PartnerFormDialog: FC<Props> = (props) => {
  const [partnerToEdit, setPartnerToEdit] = useState<partner | null>(null);
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const { user, partnerId, allPartners, show, onClose, setSnack } = props;

  const classes = useStyles();

  useEffect(() => {
    if (!partnerId || 0) {
      setPartnerToEdit({ partner: undefined, uid: null, new: true });
      return;
    }
    const p = allPartners.filter(
      (y: { uid: number }) => y.uid === partnerId
    );
    setPartnerToEdit(p[0]);
  }, [partnerId, allPartners]);

  let handleValidate = (formData: partner, errors: any) => {
    // Check for duplicate ID on new contracts, or when partner_id has changed.
    //name must be unique
    if (!formData.partner || !formData) {
      errors.partner_id.addError("Partner Name is required");
    }
    if (formData.new || formData.partner !== partnerToEdit?.partner) {
      const res = allPartners.some((o: partner) => {
        if (o.partner == null) return false;
        return formData?.partner?.toLowerCase() === o.partner.toLowerCase();
      });

      if (res) {
        errors.partner.addError("Partner Name must be unique");
      }
    }
    return errors;
  };

  function handleSubmit({ formData, cancel }: any) {
    if (cancel) {
      onClose(false);
    }
    // Check to make sure we have a record to submit
    setSubmissionLoading(true);

    if (!formData || cancel) {
      console.error("No formData");
      setSubmissionLoading(false);
      setSnack({
        show: true,
        severity: "info",
        alert: (
          <>
            Cancelled.{" "}
            <RouterLink
              to="/partners"
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              Go back to all partners
            </RouterLink>
          </>
        ),
      });
      onClose(false);
      return;
    }

    // Check to see if record has changed
    if (!deepEqual(formData, partnerToEdit)) {
      formData.lastEditedBy = user.profileObj.email;
      if (formData.new) {
        insert("partners", formData)
          .then(() => {
            setSnack({
              show: true,
              alert: "Record saved.",
              severity: "success",
            });
            setSubmissionLoading(false);
          })
          .catch((err) => {
            console.log("recordGrid Catch");
            console.dir(err);
            setSnack({
              show: true,
              alert: "Record not inserted.",
              severity: "error",
            });
            setSubmissionLoading(false);
          })
          .finally(() => onClose(true));
      } else
        update("partners", formData, partnerToEdit)
          .then(() => {
            setSnack({
              show: true,
              alert: "Record saved.",
              severity: "success",
            });
            setSubmissionLoading(false);
          })
          .catch((err) => {
            setSubmissionLoading(false);
            // An API error or partial failure occurred.
            console.log(err);
            setSnack({
              show: true,
              alert: "Update error.",
              severity: "warning",
            });
            console.log("Update Error");
            if (err.name === "PartialFailureError") {
              // Some rows failed to insert, while others may have succeeded.

              // err.errors (object[]):
              // err.errors[].row (original row object passed to `insert`)
              console.dir("errors object", err.errors[0]);
              console.log("reason code", err.errors[0].errors[0].reason);
              console.log("message", err.errors[0].errors[0].message);
              // err.errors[].errors[].message
              setSnack({
                show: true,
                alert: `Insert Error: ${err.errors[0].errors[0].message}`,
                severity: "error",
              });
            }
          })
          .finally(() => onClose(true));
    } else {
      // Record hasn't changed - user cancelled.
      setSnack({
        show: true,
        alert: (
          <>
            {" "}
            No changes made. Operation cancelled.{" "}
            <RouterLink
              to="/partners"
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Go back to all partners
            </RouterLink>
          </>
        ),
        severity: "info",
      });
      setSubmissionLoading(false);
      onClose();
    }
  }

  return (
    <Dialog open={show} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle id={`contract-form-title`}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            {partnerToEdit?.new ? "Add Partner" : "Edit Partner Details"}
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={1} style={{ textAlign: "right" }}>
            <IconButton
              aria-label="close"
              onClick={() => onClose(false)}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Form
          className={classes.rjsf}
          schema={schema}
          formData={partnerToEdit}
          ObjectFieldTemplate={PartnerFieldTemplate}
          uiSchema={partnerUiSchema}
          onSubmit={handleSubmit}
          validate={handleValidate}
        >
          <Grid container spacing={2} className={classes.formButtons}>
            <Grid item xs={5} />
            <Grid item xs={1}>
              {submissionLoading ? (
                <CircularProgress />
              ) : (
                <Button variant="outlined" color="primary" type="submit">
                  Submit
                </Button>
              )}
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="outlined"
                color="secondary"
                type="button"
                onClick={() => onClose(false)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={5} />
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  rjsf: {
    margin: "0 8px 0 8px",
  },
  formButtons: {
    textAlign: "center",
    margin: theme.spacing(2),
  },
}));


export default PartnerFormDialog;
